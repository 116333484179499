
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91event_93nf0XKaGaJuMeta } from "/home/bede/bede-web/pages/calendar/[event].vue?macro=true";
import { default as indexxd4rOTsqf9Meta } from "/home/bede/bede-web/pages/index.vue?macro=true";
import { default as bruksvilkarByU9JJHs4IMeta } from "/home/bede/bede-web/pages/juridisk/bruksvilkar.vue?macro=true";
import { default as informasjonskapslerGJSeSrBlkxMeta } from "/home/bede/bede-web/pages/juridisk/informasjonskapsler.vue?macro=true";
import { default as personvernddcSKgtdeOMeta } from "/home/bede/bede-web/pages/juridisk/personvern.vue?macro=true";
import { default as kartE1Qrb2gZ5qMeta } from "/home/bede/bede-web/pages/kart.vue?macro=true";
import { default as legg_45til_45kalenderFFWO0ycfIKMeta } from "/home/bede/bede-web/pages/legg-til-kalender.vue?macro=true";
import { default as om_45oss1erP9PtKn5Meta } from "/home/bede/bede-web/pages/om-oss.vue?macro=true";
import { default as _91event_93XOqxH6RdC3Meta } from "/home/bede/bede-web/pages/program/[event].vue?macro=true";
import { default as indexHNgTXeGI9xMeta } from "/home/bede/bede-web/pages/program/index.vue?macro=true";
import { default as analytics4ZyZ5213P3Meta } from "/home/bede/bede-web/pages/vuefire/analytics.vue?macro=true";
import { default as app_45checkBoAKsEh1XQMeta } from "/home/bede/bede-web/pages/vuefire/app-check.vue?macro=true";
import { default as countervnQJTjyDSGMeta } from "/home/bede/bede-web/pages/vuefire/counter.vue?macro=true";
import { default as database_45todo_45listyjoVA0GaIkMeta } from "/home/bede/bede-web/pages/vuefire/database-todo-list.vue?macro=true";
import { default as emoji_45panelJWQ4k7mh8rMeta } from "/home/bede/bede-web/pages/vuefire/emoji-panel.vue?macro=true";
import { default as indexsA5AngTFOPMeta } from "/home/bede/bede-web/pages/vuefire/index.vue?macro=true";
import { default as login163AtDq5VhMeta } from "/home/bede/bede-web/pages/vuefire/login.vue?macro=true";
import { default as newOWwa82nlVtMeta } from "/home/bede/bede-web/pages/vuefire/posts/new.vue?macro=true";
import { default as storagedgqFX0WAhSMeta } from "/home/bede/bede-web/pages/vuefire/storage.vue?macro=true";
import { default as usersw7VHZ3EJrmMeta } from "/home/bede/bede-web/pages/vuefire/users.vue?macro=true";
export default [
  {
    name: "calendar-event",
    path: "/calendar/:event()",
    component: () => import("/home/bede/bede-web/pages/calendar/[event].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/bede/bede-web/pages/index.vue")
  },
  {
    name: "juridisk-bruksvilkar",
    path: "/juridisk/bruksvilkar",
    component: () => import("/home/bede/bede-web/pages/juridisk/bruksvilkar.vue")
  },
  {
    name: "juridisk-informasjonskapsler",
    path: "/juridisk/informasjonskapsler",
    component: () => import("/home/bede/bede-web/pages/juridisk/informasjonskapsler.vue")
  },
  {
    name: "juridisk-personvern",
    path: "/juridisk/personvern",
    component: () => import("/home/bede/bede-web/pages/juridisk/personvern.vue")
  },
  {
    name: "kart",
    path: "/kart",
    component: () => import("/home/bede/bede-web/pages/kart.vue")
  },
  {
    name: "legg-til-kalender",
    path: "/legg-til-kalender",
    component: () => import("/home/bede/bede-web/pages/legg-til-kalender.vue")
  },
  {
    name: "om-oss",
    path: "/om-oss",
    component: () => import("/home/bede/bede-web/pages/om-oss.vue")
  },
  {
    name: "program-event",
    path: "/program/:event()",
    component: () => import("/home/bede/bede-web/pages/program/[event].vue")
  },
  {
    name: "program",
    path: "/program",
    component: () => import("/home/bede/bede-web/pages/program/index.vue")
  },
  {
    name: "vuefire-analytics",
    path: "/vuefire/analytics",
    meta: analytics4ZyZ5213P3Meta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/analytics.vue")
  },
  {
    name: "vuefire-app-check",
    path: "/vuefire/app-check",
    meta: app_45checkBoAKsEh1XQMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/app-check.vue")
  },
  {
    name: "vuefire-counter",
    path: "/vuefire/counter",
    meta: countervnQJTjyDSGMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/counter.vue")
  },
  {
    name: "vuefire-database-todo-list",
    path: "/vuefire/database-todo-list",
    meta: database_45todo_45listyjoVA0GaIkMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/database-todo-list.vue")
  },
  {
    name: "vuefire-emoji-panel",
    path: "/vuefire/emoji-panel",
    meta: emoji_45panelJWQ4k7mh8rMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/emoji-panel.vue")
  },
  {
    name: "vuefire",
    path: "/vuefire",
    component: () => import("/home/bede/bede-web/pages/vuefire/index.vue")
  },
  {
    name: "vuefire-login",
    path: "/vuefire/login",
    meta: login163AtDq5VhMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/login.vue")
  },
  {
    name: "vuefire-posts-new",
    path: "/vuefire/posts/new",
    meta: newOWwa82nlVtMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/posts/new.vue")
  },
  {
    name: "vuefire-storage",
    path: "/vuefire/storage",
    meta: storagedgqFX0WAhSMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/storage.vue")
  },
  {
    name: "vuefire-users",
    path: "/vuefire/users",
    meta: usersw7VHZ3EJrmMeta || {},
    component: () => import("/home/bede/bede-web/pages/vuefire/users.vue")
  }
]