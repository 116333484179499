import payload_plugin_xJSZTyZkKx from "/home/bede/bede-web/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_encoding@0.1.13__firebase-_v4bnfi67vpn3batj6df5m4tgdu/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_ak7bQssQul from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LBgV70Pwoc from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pLyqKvuzBj from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_zOjJRiHHNL from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dXsWLDkpAq from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_z2rtCllGoC from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/bede/bede-web/.nuxt/components.plugin.mjs";
import prefetch_client_SRU8fVEv2m from "/home/bede/bede-web/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.0_db0@0.2.1_encoding@0.1.13_ioredis@5.4.1_roggurc7f7ou4p5yhegbt7n47a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_sQWSWKicZF from "/home/bede/bede-web/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_encoding@0.1.13__firebase-_v4bnfi67vpn3batj6df5m4tgdu/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/bede/bede-web/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/bede/bede-web/.nuxt/vuefire-plugin.mjs";
import plugin_client_3B0Be9ElNB from "/home/bede/bede-web/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_encoding@0.1.13__firebase-_v4bnfi67vpn3batj6df5m4tgdu/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import analytics_client_mtqVw3YRXk from "/home/bede/bede-web/plugins/analytics.client.ts";
import vFocus_TH5s1YuUk1 from "/home/bede/bede-web/plugins/vFocus.ts";
export default [
  payload_plugin_xJSZTyZkKx,
  revive_payload_client_ak7bQssQul,
  unhead_LBgV70Pwoc,
  router_pLyqKvuzBj,
  navigation_repaint_client_zOjJRiHHNL,
  check_outdated_build_client_dXsWLDkpAq,
  chunk_reload_client_z2rtCllGoC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SRU8fVEv2m,
  plugin_client_sQWSWKicZF,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_3B0Be9ElNB,
  analytics_client_mtqVw3YRXk,
  vFocus_TH5s1YuUk1
]